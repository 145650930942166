import React from 'react'
import Top15 from '../components/Top15/Top15'

const Comercial = () => {
  return (
    <div>
      <Top15 />
    </div>
  )
}

export default Comercial